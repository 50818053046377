table {
  th,
  td {
    // overwrite material stuff for inline input
    overflow-x: visible !important;
    overflow-y: visible !important;
  }

  @include mq(xs) {
    th,
    td {
      padding: 0 $s;

      &:first-child {
        //padding-left: 0;
      }

      &:last-child {
        //padding-right: 0;
      }
    }
  }
}

.table-wrapper {
  overflow: auto;
  border-radius: $card-border-radius;
}

$this-table-min-height: $s * 2;
$this-table-shadow: $whiteframe-shadow-1dp;

div.material-table {
  box-shadow: $this-table-shadow;
  border-radius: $card-border-radius;

  table {
    width: 100%;
    box-shadow: none;
  }
}

table.material-table,
div.material-table {
  box-shadow: $this-table-shadow;
  font-size: 13px;
  white-space: nowrap;
  background-color: #fff;

  &,
  * {
    user-select: text;
    -webkit-touch-callout: default;
  }

  &._no-elevation {
    box-shadow: none;
    background-color: transparent;
  }

  &,
  table {
    border-collapse: collapse;
  }

  .isDarkTheme & {
    background: $dark-theme-card-bg;
    // NOTE: because we're on a lighter background
    color: $dark-theme-text-color;
  }

  .isDarkTheme &._no-elevation {
    background-color: transparent;
  }

  tr {
    min-height: $this-table-min-height;

    &:first-of-type {
      th,
      td {
        border-top-width: 0;
      }
    }

    &:last-of-type {
      th,
      td {
        border-bottom-width: 0;
      }
    }
  }

  th,
  td {
    vertical-align: middle;
    min-height: $this-table-min-height;
    position: relative;
    border-top: 1px solid;
    border-bottom: 1px solid;
    padding: $s $s * 2.25;
    box-sizing: border-box;
    text-align: left;

    .isLightTheme & {
      border-color: $light-theme-extra-border-color;
    }

    .isDarkTheme & {
      border-color: $dark-theme-extra-border-color;
    }

    &:first-of-type {
      padding-left: $s * 3;
    }

    &:last-of-type {
      padding-right: $s * 3;
    }
  }
}
