[enlarge-image] {
  cursor: zoom-in;
}

.enlarged-image-wrapper {
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  cursor: zoom-out;
  display: flex; /* establish flex container */
  flex-direction: column; /* make main axis vertical */
  justify-content: center; /* center items vertically, in this case */
  align-items: center; /* center items horizontally, in this case */
  //background: rgba(0, 0, 0, 0.2);
  animation: $transition-standard fadeIn;
  z-index: $z-backdrop;

  &.ani-enter {
    background: transparent;
  }

  &:after {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    opacity: 0;

    @include lightTheme(true) {
      background-color: $c-backdrop-light-theme;
    }
    @include darkTheme(true) {
      background-color: $c-backdrop-dark-theme;
    }
  }

  &.ani-enter,
  &.ani-enter:after {
    transition: $transition-enter;
  }

  &.ani-leave,
  &.ani-leave:after {
    transition: $transition-leave;
  }

  &.ani-enter:after {
    opacity: 1;
  }

  &.ani-remove:after {
    opacity: 0;
  }
}

.enlarged-image {
  z-index: 10;
  user-select: none;
  -webkit-user-select: none; /* Safari */
  max-width: 100%;
  max-height: 100%;
  transform-origin: top left;
  position: relative;
  opacity: 0.5;
  -webkit-user-drag: none;
  -khtml-user-drag: none;
  -moz-user-drag: none;
  -o-user-drag: none;
  box-shadow: $whiteframe-shadow-5dp;

  &:hover {
    cursor: zoom-in;
  }

  .isZoomed &:hover {
    cursor: zoom-out;
  }

  .ani-enter & {
    opacity: 1;
    transition: $transition-enter;
  }

  .ani-remove & {
    transition: $transition-leave;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
