@mixin _scrollBarStyles() {
  scrollbar-color: #888 #f1f1f1;
  scrollbar-width: 4px;

  ::-webkit-scrollbar {
    width: 4px;
    height: 4px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 4px;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 16px;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }

  &.isDarkTheme {
    scrollbar-color: #333 #222;

    ::-webkit-scrollbar-track {
      background: #222;
      border-radius: 4px;
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
      background: rgb(74, 74, 74);
      border-radius: 16px;
    }

    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
      background: #444;
    }
  }
}

@include mq(xs) {
  .isNoMac {
    @include _scrollBarStyles;
  }
}

@include mq(xs, max) {
  .isNoTouchOnly {
    @include _scrollBarStyles;
  }
}
