@use "sass:meta";

// used to circumvent node sass issue: https://github.com/sass/node-sass/issues/2251
@function rgb($r, $g: null, $b: null) {
  @if ($g == null) {
    @return unquote("#{$r}");
  }

  @if ($g and $b) {
    @return unquote("#{$r}, #{$g}, #{$b}");
  }

  @error "wrong number of arguments";
}

// used to circumvent node sass issue: https://github.com/sass/node-sass/issues/2251
@function rgba($r, $g, $b: null, $a: null) {
  @if ($b == null) {
    @return unquote("#{$r}, #{$g}");
  }

  @if ($b and $a) {
    @return unquote("#{$r}, #{$g}, #{$b}, #{$a}");
  }

  @error "wrong number of arguments";
}

@function str-replace($string, $search, $replace: "") {
  $index: str-index($string, $search);

  @if $index {
    @return str-slice($string, 1, $index - 1) + $replace +
      str-replace(
        str-slice($string, $index + str-length($search)),
        $search,
        $replace
      );
  }

  @return $string;
}

@mixin root($varMap: null) {
  @at-root :root {
    @each $varName, $varValue in $varMap {
      @if (meta.type-of($varValue) == string) {
        #{$varName}: $varValue; // to prevent quotes interpolation
      } @else {
        #{$varName}: #{$varValue};
      }
    }
  }
}

@function strip-variable($color) {
  $var: $color;
  @if (str-index($var, "rgba(")) {
    $var: str-replace($var, "rgba(", "");
  }
  @if (str-index($var, "rgb(")) {
    $var: str-replace($var, "rgb(", "");
  }
  @if (str-index($var, "var(")) {
    $var: str-replace($var, ")", ""); // remove excess ")"
    $var: $var + ")";
  }
  @return $var;
}
