@use "@angular/material" as mat;
@use "public-color-util";
@use "sass:map";

$dark-theme-selector: ".isDarkTheme" !default;
$light-theme-selector: ".isLightTheme" !default;

$dark-primary-text-no-rgb: 0, 0, 0, 0.87;
$dark-secondary-text-no-rgb: 0, 0, 0, 0.54;
$dark-disabled-text-no-rgb: 0, 0, 0, 0.38;
$dark-dividers-no-rgb: 0, 0, 0, 0.12;
$dark-focused-no-rgb: 0, 0, 0, 0.12;
$light-primary-text-no-rgb: 255, 255, 255, 1;
$light-secondary-text-no-rgb: 255, 255, 255, 0.7;
$light-disabled-text-no-rgb: 255, 255, 255, 0.5;
$light-dividers-no-rgb: 255, 255, 255, 0.12;
$light-focused-no-rgb: 255, 255, 255, 0.12;

$text: (
  --dark-primary-text-no-rgb: $dark-primary-text-no-rgb,
  --dark-secondary-text-no-rgb: $dark-secondary-text-no-rgb,
  --dark-accent-text-no-rgb: $dark-primary-text-no-rgb,
  --dark-warn-text-no-rgb: $dark-primary-text-no-rgb,
  --dark-disabled-text-no-rgb: $dark-disabled-text-no-rgb,
  --dark-dividers-no-rgb: $dark-dividers-no-rgb,
  --dark-focused-no-rgb: $dark-focused-no-rgb,
  --light-primary-text-no-rgb: $light-primary-text-no-rgb,
  --light-secondary-text-no-rgb: $light-secondary-text-no-rgb,
  --light-accent-text-no-rgb: $light-primary-text-no-rgb,
  --light-warn-text-no-rgb: $light-primary-text-no-rgb,
  --light-disabled-text-no-rgb: $light-disabled-text-no-rgb,
  --light-dividers-no-rgb: $light-dividers-no-rgb,
  --light-focused-no-rgb: $light-focused-no-rgb,
  --dark-primary-text: rgba(var(--dark-primary-text-no-rgb)),
  --dark-secondary-text: rgba(var(--dark-secondary-text-no-rgb)),
  --dark-accent-text: rgba(var(--dark-accent-text-no-rgb)),
  --dark-warn-text: rgba(var(--dark-warn-text-no-rgb)),
  --dark-disabled-text: rgba(var(--dark-disabled-text-no-rgb)),
  --dark-dividers: rgba(var(--dark-dividers-no-rgb)),
  --dark-focused: rgba(var(--dark-focused-no-rgb)),
  --light-primary-text: rgba(var(--light-primary-text-no-rgb)),
  --light-secondary-text: rgba(var(--light-secondary-text-no-rgb)),
  --light-accent-text: rgba(var(--light-accent-text-no-rgb)),
  --light-warn-text: rgba(var(--light-warn-text-no-rgb)),
  --light-disabled-text: rgba(var(--light-disabled-text-no-rgb)),
  --light-dividers: rgba(var(--light-dividers-no-rgb)),
  --light-focused: rgba(var(--light-focused-no-rgb)),
  --dark-text-contrast: #000000,
  --light-text-contrast: var(--light-primary-text),
) !default;

$default-light-theme: (
  // PRIMARY
  --palette-primary-50-no-rgb: public-color-util.hex-to-rgb(#e1f5fe),
  --palette-primary-100-no-rgb: public-color-util.hex-to-rgb(#b3e5fc),
  --palette-primary-200-no-rgb: public-color-util.hex-to-rgb(#81d4fa),
  --palette-primary-300-no-rgb: public-color-util.hex-to-rgb(#4fc3f7),
  --palette-primary-400-no-rgb: public-color-util.hex-to-rgb(#29b6f6),
  --palette-primary-500-no-rgb: public-color-util.hex-to-rgb(#03a9f4),
  --palette-primary-600-no-rgb: public-color-util.hex-to-rgb(#039be5),
  --palette-primary-700-no-rgb: public-color-util.hex-to-rgb(#0288d1),
  --palette-primary-800-no-rgb: public-color-util.hex-to-rgb(#0277bd),
  --palette-primary-900-no-rgb: public-color-util.hex-to-rgb(#01579b),
  --palette-primary-A100-no-rgb: public-color-util.hex-to-rgb(#80d8ff),
  --palette-primary-A200-no-rgb: public-color-util.hex-to-rgb(#40c4ff),
  --palette-primary-A400-no-rgb: public-color-util.hex-to-rgb(#00b0ff),
  --palette-primary-A700-no-rgb: public-color-util.hex-to-rgb(#0091ea),
  --palette-primary-contrast-50-no-rgb: var(--dark-primary-text-no-rgb),
  --palette-primary-contrast-100-no-rgb: var(--dark-primary-text-no-rgb),
  --palette-primary-contrast-200-no-rgb: var(--dark-primary-text-no-rgb),
  --palette-primary-contrast-300-no-rgb: var(--dark-primary-text-no-rgb),
  --palette-primary-contrast-400-no-rgb: var(--dark-primary-text-no-rgb),
  --palette-primary-contrast-500-no-rgb: var(--dark-primary-text-no-rgb),
  --palette-primary-contrast-600-no-rgb: var(--dark-primary-text-no-rgb),
  --palette-primary-contrast-700-no-rgb: var(--dark-primary-text-no-rgb),
  --palette-primary-contrast-800-no-rgb: var(--dark-primary-text-no-rgb),
  --palette-primary-contrast-900-no-rgb: var(--dark-primary-text-no-rgb),
  --palette-primary-contrast-A100-no-rgb: var(--dark-primary-text-no-rgb),
  --palette-primary-contrast-A200-no-rgb: var(--dark-primary-text-no-rgb),
  --palette-primary-contrast-A400-no-rgb: var(--dark-primary-text-no-rgb),
  --palette-primary-contrast-A700-no-rgb: var(--dark-primary-text-no-rgb),
  --palette-primary-50: var(--palette-primary-50-rgb),
  --palette-primary-100: var(--palette-primary-100-rgb),
  --palette-primary-200: var(--palette-primary-200-rgb),
  --palette-primary-300: var(--palette-primary-300-rgb),
  --palette-primary-400: var(--palette-primary-400-rgb),
  --palette-primary-500: var(--palette-primary-500-rgb),
  --palette-primary-600: var(--palette-primary-600-rgb),
  --palette-primary-700: var(--palette-primary-700-rgb),
  --palette-primary-800: var(--palette-primary-800-rgb),
  --palette-primary-900: var(--palette-primary-900-rgb),
  --palette-primary-A100: var(--palette-primary-A100-rgb),
  --palette-primary-A200: var(--palette-primary-A200-rgb),
  --palette-primary-A400: var(--palette-primary-A400-rgb),
  --palette-primary-A700: var(--palette-primary-A700-rgb),
  --palette-primary-contrast-50: var(--palette-primary-contrast-50-rgb),
  --palette-primary-contrast-100: var(--palette-primary-contrast-100-rgb),
  --palette-primary-contrast-200: var(--palette-primary-contrast-200-rgb),
  --palette-primary-contrast-300: var(--palette-primary-contrast-300-rgb),
  --palette-primary-contrast-400: var(--palette-primary-contrast-400-rgb),
  --palette-primary-contrast-500: var(--palette-primary-contrast-500-rgb),
  --palette-primary-contrast-600: var(--palette-primary-contrast-600-rgb),
  --palette-primary-contrast-700: var(--palette-primary-contrast-700-rgb),
  --palette-primary-contrast-800: var(--palette-primary-contrast-800-rgb),
  --palette-primary-contrast-900: var(--palette-primary-contrast-900-rgb),
  --palette-primary-contrast-A100: var(--palette-primary-contrast-A100-rgb),
  --palette-primary-contrast-A200: var(--palette-primary-contrast-A200-rgb),
  --palette-primary-contrast-A400: var(--palette-primary-contrast-A400-rgb),
  --palette-primary-contrast-A700: var(--palette-primary-contrast-A700-rgb),
  // ACCENT
  --palette-accent-50-no-rgb: public-color-util.hex-to-rgb(#fce4ec),
  --palette-accent-100-no-rgb: public-color-util.hex-to-rgb(#f8bbd0),
  --palette-accent-200-no-rgb: public-color-util.hex-to-rgb(#f48fb1),
  --palette-accent-300-no-rgb: public-color-util.hex-to-rgb(#f06292),
  --palette-accent-400-no-rgb: public-color-util.hex-to-rgb(#ec407a),
  --palette-accent-500-no-rgb: public-color-util.hex-to-rgb(#e91e63),
  --palette-accent-600-no-rgb: public-color-util.hex-to-rgb(#d81b60),
  --palette-accent-700-no-rgb: public-color-util.hex-to-rgb(#c2185b),
  --palette-accent-800-no-rgb: public-color-util.hex-to-rgb(#ad1457),
  --palette-accent-900-no-rgb: public-color-util.hex-to-rgb(#880e4f),
  --palette-accent-A100-no-rgb: public-color-util.hex-to-rgb(#ff80ab),
  --palette-accent-A200-no-rgb: public-color-util.hex-to-rgb(#ff4081),
  --palette-accent-A400-no-rgb: public-color-util.hex-to-rgb(#f50057),
  --palette-accent-A700-no-rgb: public-color-util.hex-to-rgb(#c51162),
  --palette-accent-contrast-50-no-rgb: var(--dark-accent-text-no-rgb),
  --palette-accent-contrast-100-no-rgb: var(--dark-accent-text-no-rgb),
  --palette-accent-contrast-200-no-rgb: var(--dark-accent-text-no-rgb),
  --palette-accent-contrast-300-no-rgb: var(--dark-accent-text-no-rgb),
  --palette-accent-contrast-400-no-rgb: var(--dark-accent-text-no-rgb),
  --palette-accent-contrast-500-no-rgb: var(--dark-accent-text-no-rgb),
  --palette-accent-contrast-600-no-rgb: var(--dark-accent-text-no-rgb),
  --palette-accent-contrast-700-no-rgb: var(--light-accent-text-no-rgb),
  --palette-accent-contrast-800-no-rgb: var(--light-accent-text-no-rgb),
  --palette-accent-contrast-900-no-rgb: var(--light-accent-text-no-rgb),
  --palette-accent-contrast-A100-no-rgb: var(--dark-accent-text-no-rgb),
  --palette-accent-contrast-A200-no-rgb: var(--dark-accent-text-no-rgb),
  --palette-accent-contrast-A400-no-rgb: var(--dark-accent-text-no-rgb),
  --palette-accent-contrast-A700-no-rgb: var(--light-accent-text-no-rgb),
  --palette-accent-50: var(--palette-accent-50-rgb),
  --palette-accent-100: var(--palette-accent-100-rgb),
  --palette-accent-200: var(--palette-accent-200-rgb),
  --palette-accent-300: var(--palette-accent-300-rgb),
  --palette-accent-400: var(--palette-accent-400-rgb),
  --palette-accent-500: var(--palette-accent-500-rgb),
  --palette-accent-600: var(--palette-accent-600-rgb),
  --palette-accent-700: var(--palette-accent-700-rgb),
  --palette-accent-800: var(--palette-accent-800-rgb),
  --palette-accent-900: var(--palette-accent-900-rgb),
  --palette-accent-A100: var(--palette-accent-A100-rgb),
  --palette-accent-A200: var(--palette-accent-A200-rgb),
  --palette-accent-A400: var(--palette-accent-A400-rgb),
  --palette-accent-A700: var(--palette-accent-A700-rgb),
  --palette-accent-contrast-50: var(--palette-accent-contrast-50-rgb),
  --palette-accent-contrast-100: var(--palette-accent-contrast-100-rgb),
  --palette-accent-contrast-200: var(--palette-accent-contrast-200-rgb),
  --palette-accent-contrast-300: var(--palette-accent-contrast-300-rgb),
  --palette-accent-contrast-400: var(--palette-accent-contrast-400-rgb),
  --palette-accent-contrast-500: var(--palette-accent-contrast-500-rgb),
  --palette-accent-contrast-600: var(--palette-accent-contrast-600-rgb),
  --palette-accent-contrast-700: var(--palette-accent-contrast-700-rgb),
  --palette-accent-contrast-800: var(--palette-accent-contrast-800-rgb),
  --palette-accent-contrast-900: var(--palette-accent-contrast-900-rgb),
  --palette-accent-contrast-A100: var(--palette-accent-contrast-A100-rgb),
  --palette-accent-contrast-A200: var(--palette-accent-contrast-A200-rgb),
  --palette-accent-contrast-A400: var(--palette-accent-contrast-A400-rgb),
  --palette-accent-contrast-A700: var(--palette-accent-contrast-A700-rgb),
  // WARN
  --palette-warn-50-no-rgb: public-color-util.hex-to-rgb(#ffebee),
  --palette-warn-100-no-rgb: public-color-util.hex-to-rgb(#ffcdd2),
  --palette-warn-200-no-rgb: public-color-util.hex-to-rgb(#ef9a9a),
  --palette-warn-300-no-rgb: public-color-util.hex-to-rgb(#e57373),
  --palette-warn-400-no-rgb: public-color-util.hex-to-rgb(#ef5350),
  --palette-warn-500-no-rgb: public-color-util.hex-to-rgb(#f44336),
  --palette-warn-600-no-rgb: public-color-util.hex-to-rgb(#e53935),
  --palette-warn-700-no-rgb: public-color-util.hex-to-rgb(#d32f2f),
  --palette-warn-800-no-rgb: public-color-util.hex-to-rgb(#c62828),
  --palette-warn-900-no-rgb: public-color-util.hex-to-rgb(#b71c1c),
  --palette-warn-A100-no-rgb: public-color-util.hex-to-rgb(#ff8a80),
  --palette-warn-A200-no-rgb: public-color-util.hex-to-rgb(#ff5252),
  --palette-warn-A400-no-rgb: public-color-util.hex-to-rgb(#ff1744),
  --palette-warn-A700-no-rgb: public-color-util.hex-to-rgb(#d50000),
  --palette-warn-contrast-50-no-rgb: var(--dark-warn-text-no-rgb),
  --palette-warn-contrast-100-no-rgb: var(--dark-warn-text-no-rgb),
  --palette-warn-contrast-200-no-rgb: var(--dark-warn-text-no-rgb),
  --palette-warn-contrast-300-no-rgb: var(--dark-warn-text-no-rgb),
  --palette-warn-contrast-400-no-rgb: var(--dark-warn-text-no-rgb),
  --palette-warn-contrast-500-no-rgb: var(--dark-warn-text-no-rgb),
  --palette-warn-contrast-600-no-rgb: var(--dark-warn-text-no-rgb),
  --palette-warn-contrast-700-no-rgb: var(--dark-warn-text-no-rgb),
  --palette-warn-contrast-800-no-rgb: var(--dark-warn-text-no-rgb),
  --palette-warn-contrast-900-no-rgb: var(--dark-warn-text-no-rgb),
  --palette-warn-contrast-A100-no-rgb: var(--dark-warn-text-no-rgb),
  --palette-warn-contrast-A200-no-rgb: var(--dark-warn-text-no-rgb),
  --palette-warn-contrast-A400-no-rgb: var(--dark-warn-text-no-rgb),
  --palette-warn-contrast-A700-no-rgb: var(--dark-warn-text-no-rgb),
  --palette-warn-50: var(--palette-warn-50-rgb),
  --palette-warn-100: var(--palette-warn-100-rgb),
  --palette-warn-200: var(--palette-warn-200-rgb),
  --palette-warn-300: var(--palette-warn-300-rgb),
  --palette-warn-400: var(--palette-warn-400-rgb),
  --palette-warn-500: var(--palette-warn-500-rgb),
  --palette-warn-600: var(--palette-warn-600-rgb),
  --palette-warn-700: var(--palette-warn-700-rgb),
  --palette-warn-800: var(--palette-warn-800-rgb),
  --palette-warn-900: var(--palette-warn-900-rgb),
  --palette-warn-A100: var(--palette-warn-A100-rgb),
  --palette-warn-A200: var(--palette-warn-A200-rgb),
  --palette-warn-A400: var(--palette-warn-A400-rgb),
  --palette-warn-A700: var(--palette-warn-A700-rgb),
  --palette-warn-contrast-50: var(--palette-warn-contrast-50-rgb),
  --palette-warn-contrast-100: var(--palette-warn-contrast-100-rgb),
  --palette-warn-contrast-200: var(--palette-warn-contrast-200-rgb),
  --palette-warn-contrast-300: var(--palette-warn-contrast-300-rgb),
  --palette-warn-contrast-400: var(--palette-warn-contrast-400-rgb),
  --palette-warn-contrast-500: var(--palette-warn-contrast-500-rgb),
  --palette-warn-contrast-600: var(--palette-warn-contrast-600-rgb),
  --palette-warn-contrast-700: var(--palette-warn-contrast-700-rgb),
  --palette-warn-contrast-800: var(--palette-warn-contrast-800-rgb),
  --palette-warn-contrast-900: var(--palette-warn-contrast-900-rgb),
  --palette-warn-contrast-A100: var(--palette-warn-contrast-A100-rgb),
  --palette-warn-contrast-A200: var(--palette-warn-contrast-A200-rgb),
  --palette-warn-contrast-A400: var(--palette-warn-contrast-A400-rgb),
  --palette-warn-contrast-A700: var(--palette-warn-contrast-A700-rgb),
  //RGB-map
  --palette-primary-50-rgb: rgb(var(--palette-primary-50-no-rgb)),
  --palette-primary-100-rgb: rgb(var(--palette-primary-100-no-rgb)),
  --palette-primary-200-rgb: rgb(var(--palette-primary-200-no-rgb)),
  --palette-primary-300-rgb: rgb(var(--palette-primary-300-no-rgb)),
  --palette-primary-400-rgb: rgb(var(--palette-primary-400-no-rgb)),
  --palette-primary-500-rgb: rgb(var(--palette-primary-500-no-rgb)),
  --palette-primary-600-rgb: rgb(var(--palette-primary-600-no-rgb)),
  --palette-primary-700-rgb: rgb(var(--palette-primary-700-no-rgb)),
  --palette-primary-800-rgb: rgb(var(--palette-primary-800-no-rgb)),
  --palette-primary-900-rgb: rgb(var(--palette-primary-900-no-rgb)),
  --palette-primary-A100-rgb: rgb(var(--palette-primary-A100-no-rgb)),
  --palette-primary-A200-rgb: rgb(var(--palette-primary-A200-no-rgb)),
  --palette-primary-A400-rgb: rgb(var(--palette-primary-A400-no-rgb)),
  --palette-primary-A700-rgb: rgb(var(--palette-primary-A700-no-rgb)),
  --palette-primary-contrast-50-rgb: var(--dark-primary-text),
  --palette-primary-contrast-100-rgb: var(--dark-primary-text),
  --palette-primary-contrast-200-rgb: var(--dark-primary-text),
  --palette-primary-contrast-300-rgb: var(--dark-primary-text),
  --palette-primary-contrast-400-rgb: var(--dark-primary-text),
  --palette-primary-contrast-500-rgb: var(--light-primary-text),
  --palette-primary-contrast-600-rgb: var(--light-primary-text),
  --palette-primary-contrast-700-rgb: var(--light-primary-text),
  --palette-primary-contrast-800-rgb: var(--light-primary-text),
  --palette-primary-contrast-900-rgb: var(--light-primary-text),
  --palette-primary-contrast-A100-rgb: var(--light-primary-text),
  --palette-primary-contrast-A200-rgb: var(--light-primary-text),
  --palette-primary-contrast-A400-rgb: var(--light-primary-text),
  --palette-primary-contrast-A700-rgb: var(--light-primary-text),
  // ACCENT
  --palette-accent-50-rgb: rgb(var(--palette-accent-50-no-rgb)),
  --palette-accent-100-rgb: rgb(var(--palette-accent-100-no-rgb)),
  --palette-accent-200-rgb: rgb(var(--palette-accent-200-no-rgb)),
  --palette-accent-300-rgb: rgb(var(--palette-accent-300-no-rgb)),
  --palette-accent-400-rgb: rgb(var(--palette-accent-400-no-rgb)),
  --palette-accent-500-rgb: rgb(var(--palette-accent-500-no-rgb)),
  --palette-accent-600-rgb: rgb(var(--palette-accent-600-no-rgb)),
  --palette-accent-700-rgb: rgb(var(--palette-accent-700-no-rgb)),
  --palette-accent-800-rgb: rgb(var(--palette-accent-800-no-rgb)),
  --palette-accent-900-rgb: rgb(var(--palette-accent-900-no-rgb)),
  --palette-accent-A100-rgb: rgb(var(--palette-accent-A100)),
  --palette-accent-A200-rgb: rgb(var(--palette-accent-A200)),
  --palette-accent-A400-rgb: rgb(var(--palette-accent-A400)),
  --palette-accent-A700-rgb: rgb(var(--palette-accent-A700)),
  --palette-accent-contrast-50-rgb: var(--dark-accent-text),
  --palette-accent-contrast-100-rgb: var(--dark-accent-text),
  --palette-accent-contrast-200-rgb: var(--dark-accent-text),
  --palette-accent-contrast-300-rgb: var(--dark-accent-text),
  --palette-accent-contrast-400-rgb: var(--dark-accent-text),
  --palette-accent-contrast-500-rgb: var(--light-accent-text),
  --palette-accent-contrast-600-rgb: var(--light-accent-text),
  --palette-accent-contrast-700-rgb: var(--light-accent-text),
  --palette-accent-contrast-800-rgb: var(--light-accent-text),
  --palette-accent-contrast-900-rgb: var(--light-accent-text),
  --palette-accent-contrast-A100-rgb: var(--light-accent-text),
  --palette-accent-contrast-A200-rgb: var(--light-accent-text),
  --palette-accent-contrast-A400-rgb: var(--light-accent-text),
  --palette-accent-contrast-A700-rgb: var(--light-accent-text),
  // WARN
  --palette-warn-50-rgb: rgb(var(--palette-warn-50-no-rgb)),
  --palette-warn-100-rgb: rgb(var(--palette-warn-100-no-rgb)),
  --palette-warn-200-rgb: rgb(var(--palette-warn-200-no-rgb)),
  --palette-warn-300-rgb: rgb(var(--palette-warn-300-no-rgb)),
  --palette-warn-400-rgb: rgb(var(--palette-warn-400-no-rgb)),
  --palette-warn-500-rgb: rgb(var(--palette-warn-500-no-rgb)),
  --palette-warn-600-rgb: rgb(var(--palette-warn-600-no-rgb)),
  --palette-warn-700-rgb: rgb(var(--palette-warn-700-no-rgb)),
  --palette-warn-800-rgb: rgb(var(--palette-warn-800-no-rgb)),
  --palette-warn-900-rgb: rgb(var(--palette-warn-900-no-rgb)),
  --palette-warn-A100-rgb: rgb(var(--palette-warn-A100-no-rgb)),
  --palette-warn-A200-rgb: rgb(var(--palette-warn-A200-no-rgb)),
  --palette-warn-A400-rgb: rgb(var(--palette-warn-A400-no-rgb)),
  --palette-warn-A700-rgb: rgb(var(--palette-warn-A700-no-rgb)),
  --palette-warn-contrast-50-rgb: var(--dark-warn-text),
  --palette-warn-contrast-100-rgb: var(--dark-warn-text),
  --palette-warn-contrast-200-rgb: var(--dark-warn-text),
  --palette-warn-contrast-300-rgb: var(--dark-warn-text),
  --palette-warn-contrast-400-rgb: var(--dark-warn-text),
  --palette-warn-contrast-500-rgb: var(--light-warn-text),
  --palette-warn-contrast-600-rgb: var(--light-warn-text),
  --palette-warn-contrast-700-rgb: var(--light-warn-text),
  --palette-warn-contrast-800-rgb: var(--light-warn-text),
  --palette-warn-contrast-900-rgb: var(--light-warn-text),
  --palette-warn-contrast-A100-rgb: var(--light-warn-text),
  --palette-warn-contrast-A200-rgb: var(--light-warn-text),
  --palette-warn-contrast-A400-rgb: var(--light-warn-text),
  --palette-warn-contrast-A700-rgb: var(--light-warn-text)
) !default;

$palette-primary: (
  50: var(--palette-primary-50),
  100: var(--palette-primary-100),
  200: var(--palette-primary-200),
  300: var(--palette-primary-300),
  400: var(--palette-primary-400),
  500: var(--palette-primary-500),
  600: var(--palette-primary-600),
  700: var(--palette-primary-700),
  800: var(--palette-primary-800),
  900: var(--palette-primary-900),
  A100: var(--palette-primary-A100),
  A200: var(--palette-primary-A200),
  A400: var(--palette-primary-A400),
  A700: var(--palette-primary-A700),
  contrast: (
    50: var(--palette-primary-contrast-50),
    100: var(--palette-primary-contrast-100),
    200: var(--palette-primary-contrast-200),
    300: var(--palette-primary-contrast-300),
    400: var(--palette-primary-contrast-400),
    500: var(--palette-primary-contrast-500),
    600: var(--palette-primary-contrast-600),
    700: var(--palette-primary-contrast-700),
    800: var(--palette-primary-contrast-800),
    900: var(--palette-primary-contrast-900),
    A100: var(--palette-primary-contrast-A100),
    A200: var(--palette-primary-contrast-A200),
    A400: var(--palette-primary-contrast-A400),
    A700: var(--palette-primary-contrast-A700),
  ),
) !default;

$palette-primary-no-rgb: (
  50: var(--palette-primary-50-no-rgb),
  100: var(--palette-primary-100-no-rgb),
  200: var(--palette-primary-200-no-rgb),
  300: var(--palette-primary-300-no-rgb),
  400: var(--palette-primary-400-no-rgb),
  500: var(--palette-primary-500-no-rgb),
  600: var(--palette-primary-600-no-rgb),
  700: var(--palette-primary-700-no-rgb),
  800: var(--palette-primary-800-no-rgb),
  900: var(--palette-primary-900-no-rgb),
  A100: var(--palette-primary-A100-no-rgb),
  A200: var(--palette-primary-A200-no-rgb),
  A400: var(--palette-primary-A400-no-rgb),
  A700: var(--palette-primary-A700-no-rgb),
  contrast: (
    50: var(--palette-primary-contrast-50-no-rgb),
    100: var(--palette-primary-contrast-100-no-rgb),
    200: var(--palette-primary-contrast-200-no-rgb),
    300: var(--palette-primary-contrast-300-no-rgb),
    400: var(--palette-primary-contrast-400-no-rgb),
    500: var(--palette-primary-contrast-500-no-rgb),
    600: var(--palette-primary-contrast-600-no-rgb),
    700: var(--palette-primary-contrast-700-no-rgb),
    800: var(--palette-primary-contrast-800-no-rgb),
    900: var(--palette-primary-contrast-900b-no-rgb),
    A100: var(--palette-primary-contrast-A100-no-rgb),
    A200: var(--palette-primary-contrast-A200-no-rgb),
    A400: var(--palette-primary-contrast-A400-no-rgb),
    A700: var(--palette-primary-contrast-A700-no-rgb),
  ),
) !default;

$palette-accent: (
  50: var(--palette-accent-50),
  100: var(--palette-accent-100),
  200: var(--palette-accent-200),
  300: var(--palette-accent-300),
  400: var(--palette-accent-400),
  500: var(--palette-accent-500),
  600: var(--palette-accent-600),
  700: var(--palette-accent-700),
  800: var(--palette-accent-800),
  900: var(--palette-accent-900),
  A100: var(--palette-accent-A100),
  A200: var(--palette-accent-A200),
  A400: var(--palette-accent-A400),
  A700: var(--palette-accent-A700),
  contrast: (
    50: var(--palette-accent-contrast-50),
    100: var(--palette-accent-contrast-100),
    200: var(--palette-accent-contrast-200),
    300: var(--palette-accent-contrast-300),
    400: var(--palette-accent-contrast-400),
    500: var(--palette-accent-contrast-500),
    600: var(--palette-accent-contrast-600),
    700: var(--palette-accent-contrast-700),
    800: var(--palette-accent-contrast-800),
    900: var(--palette-accent-contrast-900),
    A100: var(--palette-accent-contrast-A100),
    A200: var(--palette-accent-contrast-A200),
    A400: var(--palette-accent-contrast-A400),
    A700: var(--palette-accent-contrast-A700),
  ),
) !default;

$palette-accent-no-rgb: (
  50: var(--palette-accent-50-no-rgb),
  100: var(--palette-accent-100-no-rgb),
  200: var(--palette-accent-200-no-rgb),
  300: var(--palette-accent-300-no-rgb),
  400: var(--palette-accent-400-no-rgb),
  500: var(--palette-accent-500-no-rgb),
  600: var(--palette-accent-600-no-rgb),
  700: var(--palette-accent-700-no-rgb),
  800: var(--palette-accent-800-no-rgb),
  900: var(--palette-accent-900-no-rgb),
  A100: var(--palette-accent-A100-no-rgb),
  A200: var(--palette-accent-A200-no-rgb),
  A400: var(--palette-accent-A400-no-rgb),
  A700: var(--palette-accent-A700-no-rgb),
  contrast: (
    50: var(--palette-accent-contrast-50-no-rgb),
    100: var(--palette-accent-contrast-100-no-rgb),
    200: var(--palette-accent-contrast-200-no-rgb),
    300: var(--palette-accent-contrast-300-no-rgb),
    400: var(--palette-accent-contrast-400-no-rgb),
    500: var(--palette-accent-contrast-500-no-rgb),
    600: var(--palette-accent-contrast-600-no-rgb),
    700: var(--palette-accent-contrast-700-no-rgb),
    800: var(--palette-accent-contrast-800-no-rgb),
    900: var(--palette-accent-contrast-900b-no-rgb),
    A100: var(--palette-accent-contrast-A100-no-rgb),
    A200: var(--palette-accent-contrast-A200-no-rgb),
    A400: var(--palette-accent-contrast-A400-no-rgb),
    A700: var(--palette-accent-contrast-A700-no-rgb),
  ),
) !default;

$palette-warn: (
  50: var(--palette-warn-50),
  100: var(--palette-warn-100),
  200: var(--palette-warn-200),
  300: var(--palette-warn-300),
  400: var(--palette-warn-400),
  500: var(--palette-warn-500),
  600: var(--palette-warn-600),
  700: var(--palette-warn-700),
  800: var(--palette-warn-800),
  900: var(--palette-warn-900),
  A100: var(--palette-warn-A100),
  A200: var(--palette-warn-A200),
  A400: var(--palette-warn-A400),
  A700: var(--palette-warn-A700),
  contrast: (
    50: var(--palette-warn-contrast-50),
    100: var(--palette-warn-contrast-100),
    200: var(--palette-warn-contrast-200),
    300: var(--palette-warn-contrast-300),
    400: var(--palette-warn-contrast-400),
    500: var(--palette-warn-contrast-500),
    600: var(--palette-warn-contrast-600),
    700: var(--palette-warn-contrast-700),
    800: var(--palette-warn-contrast-800),
    900: var(--palette-warn-contrast-900),
    A100: var(--palette-warn-contrast-A100),
    A200: var(--palette-warn-contrast-A200),
    A400: var(--palette-warn-contrast-A400),
    A700: var(--palette-warn-contrast-A700),
  ),
) !default;

$palette-warn-no-rgb: (
  50: var(--palette-warn-50-no-rgb),
  100: var(--palette-warn-100-no-rgb),
  200: var(--palette-warn-200-no-rgb),
  300: var(--palette-warn-300-no-rgb),
  400: var(--palette-warn-400-no-rgb),
  500: var(--palette-warn-500-no-rgb),
  600: var(--palette-warn-600-no-rgb),
  700: var(--palette-warn-700-no-rgb),
  800: var(--palette-warn-800-no-rgb),
  900: var(--palette-warn-900-no-rgb),
  A100: var(--palette-warn-A100-no-rgb),
  A200: var(--palette-warn-A200-no-rgb),
  A400: var(--palette-warn-A400-no-rgb),
  A700: var(--palette-warn-A700-no-rgb),
  contrast: (
    50: var(--palette-warn-contrast-50-no-rgb),
    100: var(--palette-warn-contrast-100-no-rgb),
    200: var(--palette-warn-contrast-200-no-rgb),
    300: var(--palette-warn-contrast-300-no-rgb),
    400: var(--palette-warn-contrast-400-no-rgb),
    500: var(--palette-warn-contrast-500-no-rgb),
    600: var(--palette-warn-contrast-600-no-rgb),
    700: var(--palette-warn-contrast-700-no-rgb),
    800: var(--palette-warn-contrast-800-no-rgb),
    900: var(--palette-warn-contrast-900-no-rgb),
    A100: var(--palette-warn-contrast-A100-no-rgb),
    A200: var(--palette-warn-contrast-A200-no-rgb),
    A400: var(--palette-warn-contrast-A400-no-rgb),
    A700: var(--palette-warn-contrast-A700-no-rgb),
  ),
) !default;

$contrast-palette: map.get($palette-primary, "contrast") !default;
$contrast-palette-no-rgb: map.get($palette-primary-no-rgb, "contrast") !default;
$contrast-palette-accent: map.get($palette-accent, "contrast") !default;
$contrast-palette-accent-no-rgb: map.get(
  $palette-accent-no-rgb,
  "contrast"
) !default;
$contrast-palette-warn: map.get($palette-warn, "contrast") !default;
$contrast-palette-warn-no-rgb: map.get(
  $palette-warn-no-rgb,
  "contrast"
) !default;
