.backdrop {
  display: block;
  opacity: 1;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: fixed;
  z-index: $z-backdrop;

  @include lightTheme(true) {
    background-color: $c-backdrop-light-theme;
  }
  @include darkTheme(true) {
    background-color: $c-backdrop-dark-theme;
  }
}
