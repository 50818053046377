:root {
  //--mdc-fab-small-container-elevation-shadow: $whiteframe-shadow-4dp;
  --mat-dialog-container-small-max-width: 94vw;
  --mat-dialog-container-max-width: 85vw;
  --mat-menu-item-label-text-size: 14px;
}

* {
  --mdc-chip-container-shape-radius: 4px !important;
  --mdc-chip-elevated-container-color: transparent !important;
  //--mdc-chip-outline-color: var(--mdc-chip-outline-color, var(--mat-sys-outline)) !important;
  --mdc-chip-outline-color: rgba(125, 125, 125, 0.4) !important;
  --mdc-chip-outline-width: 1px !important;
}

.mat-drawer.mat-drawer-side {
  // should be below notes panel
  z-index: 1 !important;
}

// on android this is not properly injected for unknown reasons
.cdk-overlay-container {
  z-index: 1000;
}

// DIALOGS
// ----------
.mat-mdc-dialog-title.mat-mdc-dialog-title,
.mdc-dialog__title.mdc-dialog__title {
  margin-bottom: 0;
  margin-top: 8px;
  display: flex;
  align-items: center;
}

.cdk-overlay-pane.mat-mdc-dialog-panel {
  @include mq(xs, max) {
    max-width: 96vw !important;
  }
}

body .mat-mdc-dialog-content {
  overflow: auto;

  mat-form-field {
    display: block;
  }
}

body .mat-mdc-dialog-surface {
  overflow: visible;
}

.mat-mdc-dialog-panel {
  @include mq(xs) {
    //--mat-dialog-container-min-width: 350px;
  }
}

.mat-mdc-dialog-title {
  vertical-align: middle;

  > * {
    vertical-align: middle;
  }

  > mat-icon:first-child {
    margin-right: 8px;
  }
}

.big {
  height: auto;
  max-height: 100vh !important;
  // mobile viewport bug fix
  max-height: -webkit-fill-available;
  width: 100% !important;
  max-width: none !important;

  @include mq(xs) {
    margin: $s * 2;
    max-height: 95vh !important;
  }
}

// FORMS
// --------
.mat-mdc-form-field {
  margin-bottom: $s * 2;
}

// needs to be global cause of cdk overlay
// is used for select-task
mat-option.isDone {
  .mdc-list-item__primary-text {
    text-decoration: line-through !important;
    opacity: 0.4 !important;
  }
}

// BUTTONS
// -----------
.mat-mdc-menu-item-text {
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  max-width: 100%;
  white-space: nowrap;

  display: flex;
  justify-content: space-between;
  align-items: center;
}

.mat-mdc-icon-button.mat-mdc-button-base {
  --mdc-icon-button-state-layer-size: 40px;
}

//.mat-mdc-mini-fab.mat-mdc-mini-fab.mat-mdc-mini-fab,
.mat-mdc-icon-button.mat-mdc-icon-button.mat-mdc-icon-button {
  width: 40px;
  height: 40px;
  padding: 0;
  display: inline-flex;
  align-items: center;
  justify-content: center;

  .mat-mdc-button-touch-target {
    width: 40px;
    height: 40px;
  }
}

// ICONS
// -----

mat-icon,
.mat-icon {
  vertical-align: middle;
}

.mat-icon.mat-icon {
  // to make utf emojis look better
  overflow: visible;
}

.mat-icon {
  // for some reason material puts that to transform and it is bad for performance
  will-change: auto !important;
}

mat-icon.mat-icon[svgicon] {
  display: flex;
  justify-content: center;
}

// AUTOCOMPLETE
// -----------------
.mdc-list-item__primary-text {
  white-space: nowrap !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
}

// CDK DRAG
/* Animate items as they're being sorted. */
.cdk-drop-list-dragging .cdk-drag {
  transition: transform 100ms cubic-bezier(0, 0, 0.2, 1);
}

/* Animate an item that has been dropped. */
.cdk-drag-animating {
  transition: transform 100ms cubic-bezier(0, 0, 0.2, 1);
}

.cdk-drag-preview {
  background: #fff !important;
  box-shadow: $whiteframe-shadow-12dp;
  cursor: grabbing !important;
  overflow: hidden;

  &:hover,
  &:active {
    cursor: grabbing !important;
  }

  @include darkTheme(true) {
    background: $dark24 !important;
  }
}

.cdk-drag-placeholder {
  cursor: grabbing;
  opacity: 0.1;
}

.cdk-drop-list-dragging {
  outline: 1px dashed $c-primary;
  border-radius: 6px;
  cursor: grabbing;
}

.cdk-drop-list-disabled {
  border: 4px solid red;
}

.cdk-drop-list-receiving {
  outline: 1px dashed $c-primary;
  border-color: $c-primary;
  border-radius: 6px;
  cursor: grabbing;
  //border: 2px solid green;
}

// MAT MENU
.mat-mdc-menu-content {
  padding: 0 !important;
}

.mat-mdc-menu-item.quick-access {
  border: 1px solid red;
}

task.cdk-drag-preview .box {
  @include darkTheme(true) {
    background: $dark24 !important;
  }
}

// MAT TOOLTIP
mat-tooltip-component {
  pointer-events: none;
}
