// NOTE: rather than using media queries we do this to assure JS and CSS method are the same
@mixin touchOnlyDevice {
  :host-context(.isTouchOnly) & {
    @content;
  }
}

@mixin noTouchOnly {
  :host-context(.isNoTouchOnly) & {
    @content;
  }
}

@mixin touchPrimaryDevice {
  :host-context(.isTouchPrimary) & {
    @content;
  }
}

@mixin mousePrimaryDevice($isGlobal: false) {
  @if $isGlobal {
    .isMousePrimary & {
      @content;
    }
  } @else {
    :host-context(.isMousePrimary) & {
      @content;
    }
  }
}
