@import 'media-queries';

@mixin scroll() {
  -webkit-overflow-scrolling: touch;
  overflow: auto;
}

@mixin scrollY() {
  -webkit-overflow-scrolling: touch;
  overflow-y: auto;
  // important for touch events like hammer swipe to work
  touch-action: pan-y;
}

@mixin scrollYImportant() {
  -webkit-overflow-scrolling: touch;
  overflow-x: hidden;
  overflow-y: auto !important;
  // important for touch events like hammer swipe to work
  touch-action: pan-y;
}
