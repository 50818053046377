.global-error-alert {
  position: fixed;
  min-width: 300px;
  border: 8px solid red;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 9999999999999;
  text-align: center;
  padding: 20px;
  background: #fff;
  max-width: 90%;
  overflow: auto;
  user-select: auto;
  -webkit-touch-callout: default;

  pre {
    font-size: 14px;
    max-width: 100%;
    white-space: normal;
  }

  button {
    font-size: 20px;
  }

  .spinner {
    width: 40px;
    height: 40px;
    border: 4px solid rgba(0, 0, 0, 0.1);
    border-top: 4px solid #3498db;
    border-radius: 50%;
    animation: globalErrorAlertSpin 1s linear infinite;
    margin: auto;
  }
}

#error-fetching-info-wrapper {
  border: 1px solid black;
  padding: 16px;
  margin: 16px;
}

@keyframes globalErrorAlertSpin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
